
import {mapMutations} from "vuex";
  export default {
    name: "DonationButton",
    methods: {
      ...mapMutations({
        showModal: 'modal/showModal',
      }),
      openDonationPopup() {
        this.showModal({componentName:'DonationModal'})
      }
    }

  }
